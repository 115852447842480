import React, { useState } from "react";

function FileUpload() {
  const [csvFile, setCsvFile] = useState(null);
  const [txtFile, setTxtFile] = useState(null);
  const [downloadLinks, setDownloadLinks] = useState({ beautified: null, jsonl: null });

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (type === "csv") {
      setCsvFile(file);
    } else if (type === "txt") {
      setTxtFile(file);
    }
  };

  const handleSubmit = async () => {
    if (!csvFile || !txtFile) {
      alert("Please upload both CSV and TXT files.");
      return;
    }
  
    const formData = new FormData();
    formData.append("csv_file_path", csvFile);
    formData.append("txt_system_path", txtFile);
  
    try {
      const response = await fetch("/finetune/summarizer", {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setDownloadLinks({
        beautified: data.beautified_file_url,
        jsonl: data.jsonl_file_url,
      });
    } catch (error) {
      console.error("Error calling API:", error);
      alert("An error occurred while processing the files.");
    }
  };

  return (
    <div>
      <h2>Fine-Tune Summarizer</h2>
      <div>
        <label>Upload CSV File:</label>
        <input type="file" accept=".csv" onChange={(e) => handleFileChange(e, "csv")} />
      </div>
      <div>
        <label>Upload TXT File:</label>
        <input type="file" accept=".txt" onChange={(e) => handleFileChange(e, "txt")} />
      </div>
      <button onClick={handleSubmit}>Submit</button>

      {downloadLinks.beautified && (
        <div>
          <h3>Download Links</h3>
          <p>
            <a href={downloadLinks.beautified} download>
              Download Beautified File
            </a>
          </p>
          <p>
            <a href={downloadLinks.jsonl} download>
              Download JSONL File
            </a>
          </p>
        </div>
      )}
    </div>
  );
}

export default FileUpload;