import React, { useState, useEffect, useCallback } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import {
  FaMusic,
  FaCogs,
  FaUsers,
} from "react-icons/fa";
import { FaThumbsDown, FaBook } from "react-icons/fa6";
import {
  TbLayoutSidebarRightCollapseFilled,
  TbLayoutSidebarLeftCollapseFilled,
} from "react-icons/tb";

const Navbar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      toggleSidebar();
    }, 5000);

    return () => clearTimeout(timer);
  }, [location, toggleSidebar]);

  const getLiClass = (path) => (location.pathname === path ? "active" : "");

  return (
    <nav className={`navbar ${collapsed ? "collapsed" : ""}`}>
      <button className="sidebar-toggle-button" onClick={toggleSidebar}>
        {collapsed ? (
          <TbLayoutSidebarRightCollapseFilled />
        ) : (
          <TbLayoutSidebarLeftCollapseFilled />
        )}
      </button>
      <ul>
        <li className={getLiClass("/arena")}>
          <Link to="/arena">
            <h3>
              <FaMusic /> <span>Arena</span>
            </h3>
          </Link>
        </li>
        <li className={getLiClass("/learning_mode")}>
          <Link to="/learning_mode">
            <h3>
              <FaCogs /> <span>Learning Mode</span>
            </h3>
          </Link>
        </li>
        <li className={getLiClass("/mistrans")}>
          <Link to="/mistrans">
            <h3>
              <FaThumbsDown /> <span>Mistrans</span>
            </h3>
          </Link>
        </li>
        <li className={getLiClass("/rules")}>
          <Link to="/rules">
            <h3>
              <FaBook /> <span>Rules</span>
            </h3>
          </Link>
        </li>
        <li className={getLiClass("/faqs")}>
          <Link to="/faqs">
            <h3>
              <FaUsers /> <span>FAQs</span>
            </h3>
          </Link>
        </li>
        {/* <li className={getLiClass("/servers")}>
          <Link to="/servers">
            <h3>
              <FaServer /> <span>Servers</span>
            </h3>
          </Link>
        </li>
        <li className={getLiClass("/graph")}>
          <Link to="/graph">
            <h3>
              <span>Graph</span>
            </h3>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
