import React, {useState, useEffect, useRef, useCallback} from "react";
import "./LearningMode.css";
import Recording from "./Recording";

const LearningMode =  () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const hasFetched = useRef(false);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [viewMode, setViewMode] = useState(false);

  //  FILTERS VARS
  const [xidSearch, setXidSearch] = useState("");
  const [store, setStore] = useState(null);
  const [caller, setCaller] = useState(null);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    fetchOptionsAndSessions();
  }, []);


  const fetchOptionsAndSessions = async (filter = "") => {
    // const baseEndpoint = "http://0.0.0.0:8081/sessions/random?count=5";
    const baseEndpoint = "https://voicebite-websocket-app.azurewebsites.net/sessions/random?count=5";

    // If a filter is provided, append it to the base URL
    const endpoint = filter ? `${baseEndpoint}&date_filter=${filter}` : baseEndpoint;

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.json();
      setRecordings(jsonData);
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  const handleViewMode = async () => {
    setViewMode(!viewMode);
    setExpandedIndex(-1);
    // const endpoint = "http://0.0.0.0:8081/sessions?xid_reviewed=true";
    const endpoint = "https://voicebite-websocket-app-staging.azurewebsites.net/sessions?xid_reviewed=true";
    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRecordings(data.data);
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  const handleLearningMode = async () => {
    setViewMode(!viewMode);
    fetchOptionsAndSessions();
  } 

  const handleToday = async () => {
    setViewMode(false);
    await fetchOptionsAndSessions("today");
  };

  // Fetch 5 items from YESTERDAY
  const handleYesterday = async () => {
    setViewMode(false);
    await fetchOptionsAndSessions("yesterday");
  };

  const handleCustomDate = async () => {
    setViewMode(false);
    if (selectedDate) {
      const customDateFormatted = selectedDate;
      await fetchOptionsAndSessions(customDateFormatted);
    } else {
      alert("Please select a custom date first.");
    }
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleCartClick = (index) => {
    if (expandedIndex === index) {
      return;
    } else {
      setExpandedIndex(index);
    }
  };

  const buildQueryParams = useCallback(() => {
    const params = {};    
    if (xidSearch) params.xid = xidSearch;
    if (store) params.store = store;
    if (caller) params.caller = caller;
    return new URLSearchParams(params).toString();
  }, [xidSearch, store, caller]);
  
  const fetchFilteredSessions = useCallback(async () => {
    const query = buildQueryParams();
    const endpoint = `https://voicebite-websocket-app-staging.azurewebsites.net/sessions?${query}`;

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.json();
      setRecordings(jsonData.data);
    } catch (err) {
      console.error("Fetch error:", err);
    }
  }, [buildQueryParams]);

  useEffect(() => {
    fetchFilteredSessions();
  }, [fetchFilteredSessions]);

  return (
    <div>
      <div>
        <h1>Learning Mode</h1>
      </div>

      <div className="date-buttons-container">
        {
          viewMode &&
          <div className="filter-container mb-0">
            <input
              type="text"
              value={store}
              onChange={(e) => setStore(e.target.value)}
              placeholder="Search Store #"
            />

            <input
              type="text"
              value={caller}
              onChange={(e) => setCaller(e.target.value)}
              placeholder="Search Caller #"
            />

            <input
              type="text"
              value={xidSearch}
              onChange={(e) => setXidSearch(e.target.value)}
              placeholder="Search XID"
            />
          </div>
        }
        

        {
          !viewMode &&
          <div className="flex align-center">
            <button className="date-button" onClick={handleToday}>
              5 from Today
            </button>
            <button className="date-button" onClick={handleYesterday}>
              5 from Yesterday
            </button>
            <button
              className={selectedDate ? "date-button" : "date-button gray-button"}
              onClick={handleCustomDate}
              
            >
              5 from Custom Date
            </button>
            <input
              type="date"
              className="date-input date-input-lm"
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
        }
        

        { viewMode ?
          <button className="view-button" onClick={handleLearningMode}>Learning Mode</button>
        :
          <button className="view-button" onClick={handleViewMode}>View Mode</button>
        }
      </div>

      {
        recordings && recordings?.map((recording, index) => (
          <Recording
            recording={recording}
            key={recording.id}
            index={index}
            isExpanded={expandedIndex === index}
            toggleExpand={toggleExpand}
            handleCartClick={handleCartClick}
            viewMode={viewMode}
          />
        ))
      }
    
    </div>
  );
}

export default LearningMode;