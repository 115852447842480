import React, { useEffect, useState } from "react";
import '../mistrans/Mistrans.css';

const PopeyesRules = () => {
  const API_URL =
    "https://postgres-orm.azurewebsites.net/rules/popeyes?api_key=ORM-21e632a0-8cc9-4584-93db-25301108fc51-e79c2f4c-e051-4778-a6ad-1da43a8a12b7";

  // Store the entire fetched data here
  const [data, setData] = useState(null);
  // Store brand in its own state
  const [brand, setBrand] = useState("");
  // Store rules in its own state (we'll store it as a string to allow editing in a textarea)
  const [rulesText, setRulesText] = useState("");

  // Fetch data on component mount
  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((fetchedData) => {
        setData(fetchedData);
        // Initialize brand and rules
        setBrand(fetchedData.brand || "");
        // Convert rules object to a string for editing
        setRulesText(JSON.stringify(fetchedData.rules || {}, null, 2));
      })
      .catch((error) => console.error("Fetch error:", error));
  }, []);

  // Handler to save updated data
  const handleSave = () => {
    if (!data) return;

    // Build the updated object
    const updatedData = {
      id: data.id,
      brand: brand,
      // Convert rulesText (string) back to an object
      rules: JSON.parse(rulesText),
    };

    fetch(API_URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((savedData) => {
        alert('Data saved successfully!');
        // Optionally update the state with the new data
        setData(savedData);
      })
      .catch((error) => alert('Failed to save data:', error));
  };

  // Render brand and rules in inputs
  return (
    <div style={{ margin: "1rem" }}>
      <h1 className="text-capitalize">{brand} Rules</h1>

      <div style={{ marginBottom: "1rem" }}>
        <label>
          <strong>Rules:</strong>
          <textarea
            style={{ width: '100%', height: '500px' }}
            value={rulesText}
            onChange={(e) => setRulesText(e.target.value)}
            //style={{ marginLeft: "0.5rem" }}
          />
        </label>
      </div>

      <div style={{ marginTop: '10px' }}>
        <button
          onClick={handleSave}
          className='save-button'
        >Save Changes</button>
      </div>
    </div>
  );
};

export default PopeyesRules;
