import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { FaPlay, FaPause } from "react-icons/fa";
import "./AudioPlayer.css";

const AudioPlayer = ({ audioUrl }) => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [regions, setRegions] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // console.log("audioUrl", audioUrl);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#ddd",
        progressColor: "#dc2b2b",
        height: 80,
        responsive: true,
        splitChannels: true,
      });

      wavesurferRef.current.load(audioUrl);

      wavesurferRef.current.on("ready", () => {
        const duration = wavesurferRef.current.getDuration();
        setDuration(duration);
        const decodedData = wavesurferRef.current.getDecodedData();

        if (decodedData) {
          const extractedRegions = extractRegions(
            decodedData.getChannelData(0),
            duration
          );
          setRegions(extractedRegions);
          regions.forEach((region) => {
            wavesurferRef.current.addRegion({
              start: region.start,
              end: region.end,
              color: "rgba(0, 123, 255, 0.3)", // Adjust color for visual clarity
            });
          });
        }
      });

      wavesurferRef.current.on("audioprocess", () => {
        setCurrentTime(wavesurferRef.current.getCurrentTime());
      });

      wavesurferRef.current.on("error", (error) => {
        console.error("WaveSurfer error:", error);
      });
    }
  }, [audioUrl, regions]);

  const togglePlayPause = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying(!isPlaying);
    wavesurferRef.current.playPause();
  };

  // console.log("regions", regions);

  return (
    <>
      <div className="audio-player">
        <button onClick={(e) => togglePlayPause(e)} className="play-pause-btn">
          {isPlaying ? <FaPause/> : <FaPlay/>}
        </button>

        <div className="time-display">
          <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
        </div>

        <div ref={waveformRef} className="waveform"></div>
      </div>
    </>
  );
};

const extractRegions = (audioData, duration) => {
  const minValue = 0.001; // Adjust this to fine-tune silence detection
  const minSilenceDuration = 0.8; // Minimum duration to consider as silence
  const mergeDuration = 0.2; // Merge silence regions closer than this duration
  const scale = duration / audioData.length;
  const silentRegions = [];

  let start = 0;
  let end = 0;
  let isSilent = false;

  for (let i = 0; i < audioData.length; i++) {
    if (audioData[i] < minValue) {
      if (!isSilent) {
        start = i;
        isSilent = true;
      }
    } else if (isSilent) {
      end = i;
      isSilent = false;
      if (scale * (end - start) > minSilenceDuration) {
        silentRegions.push({
          start: scale * start,
          end: scale * end,
        });
      }
    }
  }

  const mergedRegions = [];
  let lastRegion = null;
  for (let i = 0; i < silentRegions.length; i++) {
    if (lastRegion && silentRegions[i].start - lastRegion.end < mergeDuration) {
      lastRegion.end = silentRegions[i].end;
    } else {
      lastRegion = silentRegions[i];
      mergedRegions.push(lastRegion);
    }
  }

  let regions = [];
  let lastEnd = 0;
  for (let i = 0; i < mergedRegions.length; i++) {
    regions.push({
      start: lastEnd,
      end: mergedRegions[i].start,
    });
    lastEnd = mergedRegions[i].end;
  }

  return regions;
};

export default AudioPlayer;
