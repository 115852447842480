import React, { useEffect, useState } from "react";
import ToggleSwitch from "./ToggleSwitch";

const Summary = ({ recording, selectedDialogIndex }) => {
  const [selectedSummary, setSelectedSummary] = useState([]);
  const [json, setJson] = useState('');
  const [showJson, setShowJson] = useState(false);

  useEffect(() => {
    if (recording?.dialog && Array.isArray(recording.dialog)) {
      const lastEntryWithSummary = [...recording.dialog].reverse().find(
        (entry) =>
          entry.summary &&
          entry.summary.order &&
          Array.isArray(entry.summary.order) &&
          entry.summary.order.length > 0
      );

      if (lastEntryWithSummary) {
        const json = lastEntryWithSummary.summary.order;
        setJson(json);
        console.log('json', json);
        const summaries = lastEntryWithSummary.summary.order.map(
          (item) => `${item.quantity}x ${item.item}`
        );
        setSelectedSummary(summaries);
      }
    }
  }, [recording.dialog]);

  console.log('selectedDialogIndex', selectedDialogIndex);

  useEffect(() => {    
    if(selectedDialogIndex !== -1) {
      const selectedDialog = recording.dialog[selectedDialogIndex];
      
      if (selectedDialog && selectedDialog.summary && selectedDialog?.summary?.order?.length > 0) {
        const json = selectedDialog.summary.order;
        setJson(json);
        const summaries = selectedDialog.summary.order.map(
          (item) => `${item.quantity}x ${item.item}`
        );
        setSelectedSummary(summaries);
      } else {
        setSelectedSummary([]);
      }
    }

  }, [selectedDialogIndex, recording.dialog]);

  return (
    <div className="fine-tune-container">
      <div className="">
        <div className="fine-tune-header">
          <h4>Summarizer</h4>          
        </div>

        {selectedSummary.length > 0 ? (
          <ul>
            {selectedSummary.map((item, index) => (
              <li key={index}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <p>No summary available</p>
        )}

        <ToggleSwitch
          labelName={"Show JSON"}
          switchToggle={(isToggled) => {
            console.log("isToggled", isToggled);            
            setShowJson(isToggled);
          }}
        />

        {json && showJson && (
          <div>
            <h4>JSON</h4>
            <div>{JSON.stringify(json, null, 2)}</div>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default Summary;
