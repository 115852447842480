import React, { useState, useEffect } from 'react';
import './Mistrans.css';

function MistransTable() {
  const [mistransData, setMistransData] = useState(null);
  const [mistransText, setMistransText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    fetch(
      'https://postgres-orm.azurewebsites.net/mistrans/popeyes?api_key=ORM-21e632a0-8cc9-4584-93db-25301108fc51-e79c2f4c-e051-4778-a6ad-1da43a8a12b7',
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch mistranscriptions');
        }
        return response.json();
      })
      .then((data) => {
        setMistransData(data);
        // Convert the `mistranscriptions` object into a pretty-printed JSON string
        setMistransText(JSON.stringify(data.mistranscriptions, null, 2));
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  // Example of how you might save changes back to the server
  const handleSave = async () => {
    if (!mistransData) return;

    let parsedMistranscriptions;
    try {
      // Parse JSON from the textarea content
      parsedMistranscriptions = JSON.parse(mistransText);
    } catch (parseError) {
      alert(`Invalid JSON. Please fix the JSON format.\n\n${parseError}`);
      return;
    }

    // Create an updated copy of mistransData
    const updatedData = {
      ...mistransData,
      mistranscriptions: parsedMistranscriptions,
    };

    try {
      // Endpoint and method may vary depending on your API
      const response = await fetch(
        `https://postgres-orm.azurewebsites.net/mistrans/upsert`,
        {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
            'api_key': 'ORM-21e632a0-8cc9-4584-93db-25301108fc51-e79c2f4c-e051-4778-a6ad-1da43a8a12b7',
            'x-api-key': 'ORM-21e632a0-8cc9-4584-93db-25301108fc51-e79c2f4c-e051-4778-a6ad-1da43a8a12b7',
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update mistranscriptions');
      }

      const updated = await response.json();
      // Set local state to whatever the server returns 
      setMistransData(updated);
      // Update the text area with the new data
      setMistransText(JSON.stringify(updated.mistranscriptions, null, 2));
      alert('Data saved successfully!');
    } catch (err) {
      alert(`Error saving data: ${err.message}`);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: 'red' }}>{error}</div>;
  if (!mistransData) return null;

  return (
    <div style={{ margin: '20px' }}>
      <h1>Mistranscriptions for {mistransData.brand}</h1>

      <div>
        <textarea
          style={{ width: '100%', height: '500px' }}
          value={mistransText}
          onChange={(e) => setMistransText(e.target.value)}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        <button
          onClick={handleSave}
          className='save-button'
        >Save Changes</button>
      </div>
    </div>
  );
}

export default MistransTable;
