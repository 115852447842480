// src/AppRouter.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Arena from "./pages/arena/Arena";
import Kitchen from "./pages/kitchen/Kitchen";
import Graph from "./pages/graph/Graph";
import LearningMode from "./pages/learning_mode/LearningMode";
import MistransTable from "./pages/mistrans/MistransTable";
import Rules from "./pages/rules/Rules";
import FAQsTable from "./pages/faqs/FAQsTable";
import SingleSession from "./pages/single_session/SingleSession";
import FileUpload from "./pages/file_upload/FileUpload";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Arena />} />
      <Route path="/arena" element={<Arena />} />
      <Route path="/kitchen" element={<Kitchen />} />
      <Route path="/learning_mode" element={<LearningMode />} />
      <Route path="/graph" element={<Graph />} />
      <Route path="/mistrans" element={<MistransTable />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/faqs" element={<FAQsTable />} />
      <Route path="/file_upload" element={<FileUpload />} />
      <Route path="/xid/:xid" element={<SingleSession />} />
    </Routes>
  );
};

export default AppRouter;
