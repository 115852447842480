import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../arena/Arena.css";
import "./SingleSession.css";
import AudioPlayer from "../arena/AudioPlayer";
import Cart from "../arena/Cart";
import FineTuneOutput from "../arena/FineTuneOutput";
import Summary from "../arena/Summary";
import {
  FaRegCopy,
  FaDownload,
  FaMusic,
} from "react-icons/fa";
import { timeAgo, convertToCST, getDialogStyle } from "../utils/utils";

const SingleSession = () => {
  const { xid } = useParams();
  const [recording, setRecording] = useState(null); // State to store the fetched recording
  const [error, setError] = useState(null); // State for error handling
  const [loading, setLoading] = useState(true); // State for loading indicator
  const index = 1;
  const isExpanded = true;
  const [audioUrlWorking, setAudioUrlWorking] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [copyDialogSuccess, setCopyDialogSuccess] = useState("");
  const [selectedDialogIndex, setSelectedDialogIndex] = useState(-1);
  const [urlCopySuccess, setUrlCopySuccess] = useState("");

  const handleUrlCopy = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setUrlCopySuccess("Copied!");
  };
   

  const handleCopy = async (recording) => {
    try {
      await navigator.clipboard.writeText(recording.xid);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 1000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const downloadLog = async (cxId) => {
    const url = `https://av.voicebite.ai/rx/${cxId}.log`;
    window.location.href = url;
  };

  const getSpeaker = (type) => {
    return type === "AI" || type === "OAI" ? "AI: " : "Cx: ";
  };

  const checkAudioUrl = async (audioUrl) => {
    try {
      const response = await fetch(audioUrl);
      if (response.status === 200) {
        setAudioUrlWorking(true);
      } else {
        setAudioUrlWorking(false);
      }
    } catch (error) {
      console.error("Error checking audio URL:", error);
      setAudioUrlWorking(false);
    }
  };

  const handleDialogClick = (index) => {
    setSelectedDialogIndex(index);
  };

  const handleCopyDialogToClipboard = (recording) => {
    // Gather all dialog text
    const dialogText = recording.order.dialog
      ?.map((dialog) => `${getSpeaker(dialog.typ)}: ${dialog.text}`)
      .join("\n");

    navigator.clipboard.writeText(dialogText);
    setCopyDialogSuccess("Copied!");
    setTimeout(() => setCopyDialogSuccess(""), 1000);
  };


  useEffect(() => {
    const fetchRecording = async () => {
      try {
        const response = await fetch(
          `https://voicebite-websocket-app-staging.azurewebsites.net/sessions?page=1&limit=1&xid=${xid}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching recording: ${response.status}`);
        }

        const data = await response.json();
        setRecording(data.data[0]); // Save the recording data in state
      } catch (err) {
        setError(err.message); // Save the error message
      } finally {
        setLoading(false); // Stop the loading indicator
      }
    };

    fetchRecording();
  }, [xid]); // Runs whenever the `xid` parameter changes

  console.log('recording', recording);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div>
      <div className="flex justify-end">
        <button onClick={handleUrlCopy} className='copy-button'>
          Copy URL
        </button>
        {
          urlCopySuccess && <span style={{ marginLeft: "8px", color: "green" }}>
            {urlCopySuccess}
          </span>
        }
      </div>

      <div
        key={index}
        className="recording"
      >
        <div className="header">
          <h1 className="brand-name">{recording.brand}</h1>
          <div className="timestamp">
            {timeAgo(recording?.created_at)}
          </div>
          <div>{convertToCST(recording?.created_at)}</div>
          <div>Store Phone: {recording.store}</div>
          <div>Caller Phone: {recording.caller}</div>
        </div>

        <div className="flex">
          <div className="transcript">
            {recording?.dialog
              ?.slice(0, isExpanded ? recording?.dialog.length : 2)
              ?.map((dialog, dialogIndex) => (
                <div
                  key={dialogIndex}
                  className="dialog"
                  style={getDialogStyle(dialog.typ, dialogIndex)}
                >
                  <span
                    onClick={() => isExpanded && handleDialogClick(dialogIndex)}
                    className="cursor-pointer underline"
                  >
                    <span className="font-bolder">
                      {getSpeaker(dialog.typ)}
                    </span>
                    {dialog.text}
                  </span>
                </div>
              ))}
          </div>

          {isExpanded && (
            <Summary
              recording={recording}
              selectedDialogIndex={selectedDialogIndex}
            />
          )}

          {isExpanded && (
            <FineTuneOutput
              recording={recording}
            />
          )}

          {/* Show the Cart only when expanded */}
          {isExpanded && recording.cart_json && Object.keys(recording.cart_json).length !== 0 && (
            <Cart cartData={recording.cart_json} />
          )}
        </div>

        {/* Show the AudioPlayer only when expanded */}
        {isExpanded && checkAudioUrl(`https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`) && (
          <div className="audio">
            {audioUrlWorking && (
              <AudioPlayer
                audioUrl={`https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`}
              />
            )}
          </div>
        )}

        <div>
          {isExpanded && (
            <div className="cx-id">
              <span className="bold">Cx ID: </span>
              {recording.xid}
              <FaRegCopy
                onClick={() => handleCopy(recording)}
                style={{ marginLeft: "8px", cursor: "pointer" }}
                title="Copy to clipboard"
                className="bold"
              />
              {copySuccess && (
                <span style={{ marginLeft: "8px", color: "green" }}>
                  {copySuccess}
                </span>
              )}

              <span
                onClick={() => downloadLog(recording.xid)}
                className="bold ml-20 cursor-pointer"
              >
                Download Log <FaDownload />
              </span>

              <span
                onClick={() => handleCopyDialogToClipboard(recording)}
                className="bold ml-20 cursor-pointer"
              >
                Copy transcript
                <FaRegCopy />
                {copyDialogSuccess && (
                  <span style={{ marginLeft: "8px", color: "green" }}>
                    {copyDialogSuccess}
                  </span>
                )}
              </span>

              <span
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = `https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`;
                  link.click();
                }}
                className="bold ml-20 cursor-pointer"
              >
                Download Audio <FaMusic />
              </span>
            </div>
          )}
        </div>

      
      </div>
    </div>
  )
}

export default SingleSession;