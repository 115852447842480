import React from "react";
import "./Cart.css";

function Cart({ cartData }) {
  const calculateSubtotalAndTax = () => {
    let subtotal = 0;
    let totalTax = 0;

    cartData?.forEach((item) => {
      // Calculate item total price (quantity * price)
      const itemTotal = item.quantity * item.price;
      subtotal += itemTotal;

      // Calculate and add tax for the item
      totalTax += itemTotal * item.tax_rate;

      // Calculate modifier prices
      item.modifiers.forEach((modifier) => {
        const modifierTotal = modifier.quantity * modifier.price;
        subtotal += modifierTotal;

        // Calculate and add tax for each modifier
        totalTax += modifierTotal * item.tax_rate;
      });
    });

    // Convert subtotal and tax from cents to dollars
    return {
      subtotal: (subtotal / 100)?.toFixed(2),
      tax: (totalTax / 100)?.toFixed(2),
    };
  };

  const { subtotal, tax } = calculateSubtotalAndTax();

  return (
    <div className="cart">
      <div className="cart-header">
        <h2>Post-Hydrate</h2>
      </div>

      <h3>Items:</h3>
      <ul className="cart-items">
        {cartData?.map((item, index) => (
          <li key={`${item.id}-${index}`} className="cart-item">
            <div className="item-details">
              <h4>
                {item.quantity}x {item.name.split("##")[0]}
              </h4>
              <div className="item-price">${(item.price / 100)?.toFixed(2)}</div>
            </div>
            <ul className="modifiers">
              {item?.modifiers?.map((modifier) => (
                <li key={modifier.id} className="modifier">
                  <span>
                    {modifier.quantity}x {modifier.cart_name}
                  </span>
                  <div className="modifier-price">
                    ${(modifier.price / 100)?.toFixed(2)}
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>

      <div className="total">
        <p className="total-line">
          <span className="flex-5" />
          <span className="order-label flex-1">Subtotal: </span>
          <strong className="flex-1 pull-right">${subtotal}</strong>
        </p>
        <p>
          <span className="flex-5" />
          <span className="order-label flex-1">Tax: </span>
          <strong className="flex-1 pull-right">${tax}</strong>
        </p>
        <p>
          <span className="flex-5" />
          <span className="order-label flex-1">Total: </span>
          <strong className="flex-1 pull-right">
            ${(parseFloat(subtotal) + parseFloat(tax))?.toFixed(2)}
          </strong>
        </p>
      </div>
    </div>
  );
}

export default Cart;
