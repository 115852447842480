import React, { useState } from "react";
import "./ToggleSwitch.css"; // For styling

const ToggleSwitch = ({ labelName, switchToggle }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    switchToggle(!isToggled); // Call the provided function with the new state
  };

  return (
    <div className="toggle-container">
      <label className="toggle-label">
        <input
          type="checkbox"
          className="toggle-input"
          checked={isToggled}
          onChange={handleToggle}
        />
        <span className="toggle-slider"></span>
      </label>
      <span className="toggle-text">{labelName}</span>
    </div>
  );
};

export default ToggleSwitch;
