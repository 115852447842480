import React, { useEffect, useState, useCallback } from "react";
import "./Arena.css";
import AudioPlayer from "./AudioPlayer";
import Cart from "./Cart";
import FineTuneOutput from "./FineTuneOutput";
import Summary from "./Summary";
import {
  FaChevronDown,
  FaChevronUp,
  FaRegCopy,
  FaDownload,
  FaMusic,
} from "react-icons/fa";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import customStyles from "./customSelectStyles";
import { timeAgo, convertToCST, getDialogStyle } from "../utils/utils";
import Pagination from "./Pagination";

const Arena = () => {
  const [recordings, setRecordings] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  const [callerOptions, setCallerOptions] = useState([]);
  const [selectedCaller, setSelectedCaller] = useState(null);

  const [transcriptSearch, setTranscriptSearch] = useState("");
  const [cartSearch, setCartSearch] = useState("");

  const [xidSearch, setXidSearch] = useState("");

  const learningModeOptions = [{label: 'True', value: 'learning'}, {label: 'False', value: ''}];
  const [selectedLearningMode, setSelectedLearningMode] = useState(null);

  const [audioUrlWorking, setAudioUrlWorking] = useState(false);
  console.log("audioUrlWorking", audioUrlWorking);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [copyDeepLink, setCopyDeepLink] = useState("");
  const [copyDialogSuccess, setCopyDialogSuccess] = useState("");
  const [selectedDialogIndex, setSelectedDialogIndex] = useState(-1);

  // Fetch dropdown options and sessions on mount
  useEffect(() => {
    const fetchOptionsAndSessions = async () => {
      const endpoint = "https://voicebite-websocket-app.azurewebsites.net/sessions";
      // const endpoint = "http://0.0.0.0:8081/sessions";

      try {
        const response = await fetch(endpoint);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();

        // Set initial data and options
        setRecordings(jsonData.data);
        const brands = [...new Set(jsonData.data.map((recording) => recording.brand))];
        const stores = [...new Set(jsonData.data.map((recording) => recording.store))];
        const callers = [...new Set(jsonData.data.map((recording) => recording.caller))];

        setBrandOptions(brands.map((brand) => ({ label: brand, value: brand })));
        setStoreOptions(stores.map((store) => ({ label: store, value: store })));
        setCallerOptions(callers.map((caller) => ({ label: caller, value: caller })));
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };

    fetchOptionsAndSessions();
  }, []);

  // Build query string dynamically based on selected filters
  const buildQueryParams = useCallback(() => {
    const params = {
      page: currentPage,
      limit: itemsPerPage,
    };
    if (selectedBrand) params.brand = selectedBrand.value;
    if (selectedStore) params.store = selectedStore.value;
    if (selectedCaller) params.caller = selectedCaller.value;
    if (selectedLearningMode) params.notes = selectedLearningMode.value;
    if (transcriptSearch) params.transcript = transcriptSearch;
    if (cartSearch) params.cart_items = cartSearch;
    if (xidSearch) params.xid = xidSearch;
    if (dateFrom) params.date_from = dateFrom;
    if (dateTo) params.date_to = dateTo;
    return new URLSearchParams(params).toString();
  }, [selectedBrand, selectedStore, selectedCaller, transcriptSearch, cartSearch, xidSearch, currentPage, itemsPerPage, selectedLearningMode, dateFrom, dateTo]);

  // Fetch data with filters applied
  const fetchFilteredSessions = useCallback(async () => {
    const query = buildQueryParams();
    const endpoint = `https://voicebite-websocket-app.azurewebsites.net/sessions?${query}`;
    // const endpoint = `http://0.0.0.0:8081/sessions?${query}`;

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const jsonData = await response.json();
      setRecordings(jsonData.data);
      setTotalItems(jsonData.total || 0); // Total items
    } catch (err) {
      console.error("Fetch error:", err);
    }
  }, [buildQueryParams]);

  // Trigger API call when any filter state changes
  useEffect(() => {
    fetchFilteredSessions();
  }, [fetchFilteredSessions]);

  const handleDateFromChange = (e) => {
    setDateFrom(e.target.value);
    setCurrentPage(1);
  };

  const handleDateToChange = (e) => {
    setDateTo(e.target.value);
    setCurrentPage(1);
  };

  // Handlers for filter changes
  const handleBrandChange = (selectedOption) => {
    setSelectedBrand(selectedOption);
    setCurrentPage(1); // Reset to page 1 on filter change
  };

  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption);
    setCurrentPage(1); // Reset to page 1 on filter change
  };

  const handleCreateStore = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setStoreOptions((prevOptions) => [...prevOptions, newOption]);
    setSelectedStore(newOption); // Automatically select the new store
  };

  const handleCallerChange = (selectedOption) => {
    setSelectedCaller(selectedOption);
    setCurrentPage(1); // Reset to page 1 on filter change
  };

  const handleCreateCaller = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setCallerOptions((prevOptions) => [...prevOptions, newOption]);
    setSelectedCaller(newOption);
  };

  const handleLearningModeChange = (selectedOption) => {
    setSelectedLearningMode(selectedOption);
    setCurrentPage(1);
  };

  const handleTranscriptSearchChange = (e) => {
    setTranscriptSearch(e.target.value);
    setCurrentPage(1); // Reset to page 1 on filter change
  };

  const handleCartSearchChange = (e) => {
    setCartSearch(e.target.value);
    setCurrentPage(1); // Reset to page 1 on filter change
  };

  const handleXidSearchChange = (e) => {
    setXidSearch(e.target.value);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSelectedBrand(null);
    setSelectedStore(null);
    setSelectedCaller(null);
    setTranscriptSearch("");
    setCartSearch("");
    setXidSearch("");
    setSelectedLearningMode(null);
    setDateFrom(null);
    setDateTo(null);
    setCurrentPage(1); // Reset to page 1
  };

  const checkAudioUrl = async (audioUrl) => {
    try {
      const response = await fetch(audioUrl);
      if (response.status === 200) {
        setAudioUrlWorking(true);
      } else {
        setAudioUrlWorking(false);
      }
    } catch (error) {
      console.error("Error checking audio URL:", error);
      setAudioUrlWorking(false);
    }
  };

  const handleTranscriptClick = (index, isExpanded) => {
    if(isExpanded) {
      setSelectedDialogIndex(index);
    }
  }








  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleCartClick = (index) => {
    if (expandedIndex === index) {
      return;
    } else {
      setSelectedDialogIndex(-1);
      setExpandedIndex(index);
    }
  };

  const handleCopy = async (recording) => {
    try {
      await navigator.clipboard.writeText(recording.xid);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 1000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const handleCopyDeepLink = async (recording) => {
    try {
      await navigator.clipboard.writeText(`https://ops2.voicebite.ai/xid/${recording.xid}`);
      setCopyDeepLink("Copied!");
      setTimeout(() => setCopyDeepLink(""), 1000);
    } catch (err) {
      setCopyDeepLink("Failed to copy!");
    }
  };

  const downloadLog = async (cxId) => {
    const url = `https://av.voicebite.ai/rx/${cxId}.log`;
    window.location.href = url;
  };

  const getSpeaker = (type) => {
    return type === "AI" || type === "OAI" ? "AI: " : "Cx: ";
  };

  const handleCopyDialogToClipboard = (recording) => {
    // Gather all dialog text
    const dialogText = recording.order.dialog
      ?.map((dialog) => `${getSpeaker(dialog.typ)}: ${dialog.text}`)
      .join("\n");

    navigator.clipboard.writeText(dialogText);
    setCopyDialogSuccess("Copied!");
    setTimeout(() => setCopyDialogSuccess(""), 1000);
  };

  // console.log("filteredRecordings", recordings);

  return (
    <div className="recordings-list">
      <div className="filter-container">
        <input
          type="date"
          value={dateFrom}
          onChange={handleDateFromChange}
          className="date-input"
          placeholder="Date From"
        />

        <input
          type="date"
          value={dateTo}
          onChange={handleDateToChange}
          className="date-input"
          placeholder="Date To"
        />
        <Select
          id="brand-filter"
          options={brandOptions}
          value={selectedBrand}
          onChange={handleBrandChange}
          placeholder="Select Brand"
          isClearable
          styles={customStyles}
        />

        <CreatableSelect
          id="store-filter"
          options={storeOptions}
          value={selectedStore}
          onChange={handleStoreChange}
          onCreateOption={handleCreateStore}
          placeholder="Select Store"
          isClearable
          styles={customStyles}
        />

        <CreatableSelect
          id="caller-create-filter"
          options={callerOptions}
          value={selectedCaller}
          onChange={handleCallerChange}
          onCreateOption={handleCreateCaller}
          placeholder="Select Caller"
          isClearable
          styles={customStyles}
        />        

        <input
          type="text"
          value={transcriptSearch}
          onChange={handleTranscriptSearchChange}
          placeholder="Search transcripts"
        />

        <input
          type="text"
          value={cartSearch}
          onChange={handleCartSearchChange}
          placeholder="Search cart"
        />

        <input
          type="text"
          value={xidSearch}
          onChange={handleXidSearchChange}
          placeholder="Search XID"
        />

        <Select
          id="learing-mode-filter"
          options={learningModeOptions}
          value={selectedLearningMode}
          onChange={handleLearningModeChange}
          placeholder="Select Learning Mode"
          isClearable
          styles={customStyles}
        />

        <button onClick={clearFilters} className="clear-button">
          Clear Filters
        </button>
      </div>

      {recordings.map((recording, index) => {
        const isExpanded = expandedIndex === index;
        return (
          <div
            key={index}
            className="recording"
            onClick={() => handleCartClick(index)}
          >
            <div className="header">
              <h1 className="brand-name">{recording.brand}</h1>
              <div className="timestamp">
                {timeAgo(recording?.created_at)}
              </div>
              <div>{convertToCST(recording?.created_at)}</div>
              <div>Store Phone: {recording.store}</div>
              <div>Caller Phone: {recording.caller}</div>
            </div>

            <div className="flex">
              <div className="transcript">
                {recording?.dialog
                  ?.slice(0, isExpanded ? recording?.dialog.length : 2)
                  ?.map((dialog, dialogIndex) => (
                    <div
                      key={dialogIndex}
                      className="dialog"
                      style={getDialogStyle(dialog.typ, dialogIndex)}
                      onClick={() => handleTranscriptClick(dialogIndex, isExpanded)}
                    >
                      <span
                        className="cursor-pointer underline"
                      >
                        <span className="font-bolder">
                          {getSpeaker(dialog.typ)}
                        </span>
                        {dialog.text}
                      </span>
                    </div>
                  ))}
              </div>

              {isExpanded && (
                <Summary recording={recording} selectedDialogIndex={selectedDialogIndex} key={index} />
              )}

              {isExpanded && (
                <FineTuneOutput
                  recording={recording}
                />
              )}

              {/* Show the Cart only when expanded */}
              {isExpanded && recording.cart_json && Object.keys(recording.cart_json).length !== 0 && (
                <Cart cartData={recording.cart_json} />
              )}
            </div>

            {/* Show the AudioPlayer only when expanded */}
            {isExpanded && checkAudioUrl(`https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`) && (
              <div className="audio">
                {audioUrlWorking && (
                  <AudioPlayer
                    audioUrl={`https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`}
                  />
                )}
              </div>
            )}

            <div>
              {isExpanded && (
                <div className="cx-id">
                  <span className="bold">Deep Link: </span>
                  <span className="cursor-pointer" onClick={() => handleCopyDeepLink(recording)}>Copy</span>
                  {copyDeepLink && (
                    <span style={{ marginLeft: "8px", color: "green" }}>
                      {copyDeepLink}
                    </span>
                  )}


                  <span className="bold ml-20">Cx ID: </span>
                  {recording.xid}
                  <FaRegCopy
                    onClick={() => handleCopy(recording)}
                    style={{ marginLeft: "8px", cursor: "pointer" }}
                    title="Copy to clipboard"
                    className="bold"
                  />
                  {copySuccess && (
                    <span style={{ marginLeft: "8px", color: "green" }}>
                      {copySuccess}
                    </span>
                  )}

                  <span
                    onClick={() => downloadLog(recording.xid)}
                    className="bold ml-20 cursor-pointer"
                  >
                    Download Log <FaDownload />
                  </span>

                  <span
                    onClick={() => handleCopyDialogToClipboard(recording)}
                    className="bold ml-20 cursor-pointer"
                  >
                    Copy transcript
                    <FaRegCopy />
                    {copyDialogSuccess && (
                      <span style={{ marginLeft: "8px", color: "green" }}>
                        {copyDialogSuccess}
                      </span>
                    )}
                  </span>

                  <span
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = `https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`;
                      link.click();
                    }}
                    className="bold ml-20 cursor-pointer"
                  >
                    Download Audio <FaMusic />
                  </span>
                </div>
              )}
            </div>

            {/* Toggle button */}
            <div onClick={() => toggleExpand(index)} className="toggle-button">
              {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
        );
      })}

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    

    </div>
  );
};

export default Arena;
