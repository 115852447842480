import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import Navbar from './Navbar';

const App = () => {
  const validUsername = "voicebite";
  const validPassword = "V0icebitten!";

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");

  // Check for remembered login on app load
  useEffect(() => {
    const rememberedAuth = localStorage.getItem("rememberedAuth");
    if (rememberedAuth) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
      setError("");

      // Save to localStorage if "Remember Me" is checked
      if (rememberMe) {
        localStorage.setItem("rememberedAuth", true);
      }
    } else {
      setError("Invalid credentials. Please try again.");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("rememberedAuth");
  };

  return (
    <div>
      {isAuthenticated ? (
        <Router>
          <div className="app-container">
            <Navbar onLogout={handleLogout} />
            <div className="content">
              <AppRouter />
            </div>
          </div>
        </Router>
      ) : (
        <div className="login-container">
          <div className="login-form">
            <h1>Login</h1>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <input
              type="text"
              name="username"
              autoComplete="username"
              className="input"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              name="password"
              autoComplete="current-password"
              className="input password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="remember-me">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="rememberMe">Remember Me</label>
            </div>
            <button className="button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
