import React, { useState, useEffect } from "react";
import "./LearningMode.css";
// import AudioPlayer from "../arena/AudioPlayer";
import { timeAgo, convertToCST, getDialogStyle } from "../utils/utils";
import Cart from "../arena/Cart";
import {
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

const Recording =  ({ recording, toggleExpand, index, isExpanded, handleCartClick, viewMode  }) => {
  const [fineTune, setFineTune] = useState(recording?.cart);
  const [correctedSummarizer, setCorrectedSummarizer] = useState(recording?.summarizer_corrected);
  const [originalSummarizer, setOriginalSummarizer] = useState('');
  const [checkmateCart, setCheckmateCart] = useState("no");
  const [saveStatus, setSaveStatus] = useState("");
  const [selectedSummary, setSelectedSummary] = useState([]);
  const [selectedDialogIndex, setSelectedDialogIndex] = useState(null);
  // const [audioUrlWorking, setAudioUrlWorking] = useState(false);

  //const summarizerBg = summarizer === recording?.summarizer ? "lightgreen" : "lightcoral";
  //const fineTuneBg = fineTune === recording?.cart ? "lightgreen" : "lightcoral";

  const [summarizerBg, setSummarizerBg] = useState('#fff');
  const [fineTuneBg, setFineTuneBg] = useState('#fff');
  const [checkmateCartBg, setCheckmateCartBg] = useState('#fff');


  // console.log("recording", recording);
  console.log('selectedSummary', selectedSummary);
  const getSpeaker = (type) => {
    return type === "AI" || type === "OAI" ? "AI: " : "Cx: ";
  };

  const handleTranscriptClick = (dialogIndex) => {
    setSelectedDialogIndex(dialogIndex);
    const selectedDialog = recording.dialog[dialogIndex];

    if (selectedDialog && selectedDialog.summary && selectedDialog.summary.order.length > 0) {
      const summaries = selectedDialog.summary.order.map(
        (item) => `${item.quantity}x ${item.item}`
      );
      setSelectedSummary(summaries);
    } else {
      setSelectedSummary([]); // Clear if no summary
    }
  };

  const handleSaveClick = async () => {
    const endpoint = `https://voicebite-websocket-app-staging.azurewebsites.net/update_session/${recording.xid}?api_key=ORM-21e632a0-8cc9-4584-93db-25301108fc51-e79c2f4c-e051-4778-a6ad-1da43a8a12b7`;

    const data = {
      xid: recording.xid,
      summarizer_corrected: correctedSummarizer,
      ft_output_corrected: fineTune,
      cm_cart_corrected: checkmateCart === "yes",
      xid_reviewed: true,
    };
    if(recording?.cart !== fineTune) {
      data.ft_output_corrected = fineTune;
    }

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const jsonData = await response.json();
      console.log('Session updated successfully:', jsonData);
      setSaveStatus("success");
      //set bg to grean or red
      setSummarizerBg(correctedSummarizer === originalSummarizer ? "lightgreen" : "lightcoral");
      setFineTuneBg(fineTune === recording?.cart ? "lightgreen" : "lightcoral");
      setCheckmateCartBg(checkmateCart === "yes" ? "lightgreen" : "lightcoral");

    } catch (error) {
      console.error('Error updating the session:', error);
      setSaveStatus("error");
    }
  };

  useEffect(() => {
    if (recording?.dialog && Array.isArray(recording.dialog)) {
      const lastEntryWithSummary = [...recording.dialog].reverse().find(
        (entry) =>
          entry.summary &&
          entry.summary.order &&
          Array.isArray(entry.summary.order) &&
          entry.summary.order.length > 0
      );

      if (lastEntryWithSummary) {
        const summaries = lastEntryWithSummary.summary.order.map(
          (item) => `${item.quantity}x ${item.item}`
        );
        setSelectedSummary(summaries);
        setOriginalSummarizer(summaries.join("\n"));
      }
    }
  }, [recording.dialog]);


  // useEffect(() => {
  //   checkAudioUrl(`https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`);
  // });

  // const checkAudioUrl = async (audioUrl) => {
  //   try {
  //     const response = await fetch(audioUrl);
  //     if (response.status === 200) {
  //       setAudioUrlWorking(true);
  //     } else {
  //       setAudioUrlWorking(false);
  //     }
  //   } catch (error) {
  //     console.error("Error checking audio URL:", error);
  //     setAudioUrlWorking(false);
  //   }
  // };

  return (
    <div className="recording" onClick={() => handleCartClick(index)}>
      <div className="header">
        <h1 className="brand-name">Popeyes</h1>
        <div className="timestamp">Just now</div>
        <div>
          {timeAgo(recording?.created_at)}
        </div>
        <div>{convertToCST(recording?.created_at)}</div>
        <div>Store Phone: {recording?.store}</div>
        <div>Caller Phone: {recording?.caller}</div>
        <div>Cx ID: {recording?.xid}</div>
      </div>

      {/* {isExpanded && (
        <div className="audio">
          <div className="audio">
            {audioUrlWorking && (
              <AudioPlayer
                audioUrl={`https://voicebite-websocket-app-staging.azurewebsites.net/session_recording/${recording.xid}`}
                
              />
            )}
          </div>
        </div>
      )} */}

      {isExpanded && (
        <div className="flex">
          <div className="transcript">
            <h3>Transcript</h3>
            {recording?.dialog
              ?.map((dialog, dialogIndex) => (
                <div
                  key={dialogIndex}
                  className={selectedDialogIndex === dialogIndex ? "selected-line" : ""}
                  style={getDialogStyle(dialog.typ, dialogIndex)}
                  onClick={() => handleTranscriptClick(dialogIndex)}
                >
                  <span                        
                    className="cursor-pointer underline"
                  >
                    <span className="font-bolder">
                      {getSpeaker(dialog.typ)}
                    </span>
                    {dialog.text}
                  </span>
                </div>
              ))}
          </div>

          <div className="flex-container">
              <div className="labels">
                <div><h3>Summarizer</h3></div>
                <div><h3>Fine-tune Output</h3></div>
                <div><h3>Checkmate Cart</h3></div>
              </div>

              <div className="original">
                <h4 className="label-original">Original</h4>
                <div className="summarizer">
                  <div className='summarizer-text'>
                    {selectedSummary.length > 0 ? (
                      <ul>
                        {selectedSummary.map((item, index) => (
                          <li key={index}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No summary available</p>
                    )}
                  </div> 
                </div>

                <div className="fine-tune">
                  <div className="fine-tune-text">
                  {recording?.cart !== null
                    ? recording.cart.split("\n").map((line, index) => (
                        <div key={index} style={{ whiteSpace: "pre" }}>
                          {line}
                        </div>
                      ))
                    : <em>No fine tune data</em>
                  }
                  </div>
                </div>

                {recording?.cart_json && Object.keys(recording?.cart_json).length !== 0
                  ? <Cart cartData={recording.cart_json} />
                  : <div className="checkmate-cart">
                      <div className="cart-text">
                        <em>No cart data</em>
                      </div>
                    </div>
                }
              </div>

              <div className="corrected">
                <h4 className="label-original">Corrected</h4>
                
                <div className="summarizer">
                  <textarea
                    className='summarizer-text'
                    value={correctedSummarizer || originalSummarizer}
                    onChange={(e) => setCorrectedSummarizer(e.target.value)}
                    style={{ backgroundColor: summarizerBg }}
                    rows="10"
                    cols="40"
                  />
                </div>

                <div className="fine-tune">
                  <textarea
                    className="fine-tune-text"
                    value={fineTune || ''}
                    onChange={(e) => setFineTune(e.target.value)}
                    style={{ backgroundColor: fineTuneBg }}
                    rows="10"
                    cols="40"
                  />
                </div>

                <div className="checkmate-cart">
                  <div className="cart-text" style={{background: checkmateCartBg}}>
                    <div>Is Checkmate Cart correct?</div>
                      <label className='cart-label'>
                      <input
                        type="radio"
                        name="checkmateCart"
                        value="yes"
                        checked={checkmateCart === "yes"}
                        onChange={(e) => setCheckmateCart(e.target.value)}
                      />
                        Yes (it looks right!)
                      </label>
                      
                      <label className='cart-label'>
                      <input
                        type="radio"
                        name="checkmateCart"
                        value="no"
                        checked={checkmateCart === "no"}
                        onChange={(e) => setCheckmateCart(e.target.value)}
                      />
                        No (it’s wrong!)
                      </label>
                  </div>
                </div>

              </div>
          </div>
        </div>
      )}
      
      {
        isExpanded && (
          <div className="footer">
            <button 
              className="footer-button"
              onClick={handleSaveClick}
            >
              {saveStatus === "success" ? "Saved" : "Save"}
            </button>
          </div>
        )
      }

      {
        !isExpanded && recording.xid_reviewed && (
          <div className="footer">
            <div className='summarizer-box' style={{background: recording.summarizer_corrected === originalSummarizer ? 'lightgreen' : 'lightcoral'}}></div>
            <div className='ft-box' style={{background: recording.ft_output_corrected === recording.cart ? 'lightgreen' : 'lightcoral' }}></div>
            <div className='cart-box' style={{background: recording?.cm_cart_corrected === true ? 'lightgreen' : 'lightcoral'}}></div>
          </div>
        )
      }

      <div onClick={() => toggleExpand(index)} className="toggle-button">
        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
      </div>

      {saveStatus === "success" && (
        <div style={{ color: "green", marginTop: "1rem", textAlign: 'right' }}>
          Session updated successfully!
        </div>
      )}

      {saveStatus === "error" && (
        <div style={{ color: "red", marginTop: "1rem", textAlign: 'right' }}>
          Something went wrong. Please try again.
        </div>
      )}
    </div>
  )
};

export default Recording;